<template>
  <div
    :class=$style.root
    :data-view-option="viewOption"
    :style="{
      '--max-z-index': entries?.length,
    }"
    ref="rootElement"
  >
    <NuxtErrorBoundary>
      <MapLinkToCloseComponent
        v-if="viewOption === 'modal'"
      />

      <ClientOnly>
        <MapboxMap
          map-id="mapElement"
          :options="{
            style: 'mapbox://styles/studiobramvandenberg/clx4hkiaw01su01pn6vok6imx',
            minZoom: zoomMin,
            maxZoom:  zoomMax,
            boxZoom: false,
            dragRotate: false,
            pitchWithRotate: false,
            // touchZoomRotate: false,
            localFontFamily: fontString,
            localIdeographFontFamily: fontString
          }"
          @zoom="setZoomValue"
          @load="onLoad"
        >
          <MapMarkerWithPopupComponent
            v-if="mapLoaded"
            v-for="entry in entries"
            :key="`${entry?.mapType}-${entry?.id}`"
            :entry="entry"
            :zoom="zoom"
            :fly-to="flyTo"
            @on-open-popup="enableDragPan(false)"
            @on-close-popup="enableDragPan(true)"
          />

          <MapGeojsonLayerComponent
            v-if="mapLoaded && citiesDataWithGeoJson"
            v-for="(entry, index) in citiesDataWithGeoJson"
            :entry="entry"
            :index="index"
            @remove-layer=removeLayer
          />

          <MapGeojsonSourceComponent
            v-if="mapLoaded && citiesDataWithGeoJson"
            v-for="(entry, index) in citiesDataWithGeoJson"
            :entry="entry"
            :index="index"
          />
        </MapboxMap>

        <nav :class="$style.nav">
          <MapExploreComponent
            :clusterEntries=clustersData?.data
            :cityEntries=citiesData?.data
            :venueEntries=venuesData?.data
            :fly-to="flyTo"
            v-show="!pending"
          />
        </nav>

        <MapZoomComponent
          :zoomTo="zoomTo"
          :zoom="zoom"
          :zoomMin="zoomMin"
          :zoomMax="zoomMax"
        />

        <MapLegendComponent
          :zoomTo="zoomTo"
          :zoom="zoom"
          v-show="!pending"
        />
      </ClientOnly>
    </NuxtErrorBoundary>
  </div>
</template>

<script setup>
const route = useRoute()
const rootElement = ref(null)
const rootElementIsVisible = useElementVisibility(rootElement)


// 1
const props = defineProps({
  viewOption: {
    type: String,
    default: 'clusters',
    validae: (value) => ['clusters', 'modal'].includes(value)
  }
})

const filterCityIds = computed(() => route.query.cities ? route.query.cities?.split(',') : [])
const filterVenueIds = computed(() => route.query.venues ? route.query.venues?.split(',') : [])
const filterCategoryIds = computed(() => route.query.categories ? route.query.categories?.split(',') : [])
const filterFocusWeekIds = computed(() => route.query['focus-weeks'] ? route.query['focus-weeks']?.split(',') : [])

const { data: clustersData, pending: clustersPending, execute: clustersExecute } = await useLazyAsyncData(() => $fetch('/api/strapi-rest', { query: { path: queryClusterEntryMapThumbnails() } }), {immediate: false} )
const { data: citiesData, pending: citiesPending, execute: citiesExecute } = await useLazyAsyncData(() => $fetch('/api/strapi-rest', { query: { path: queryCityEntryMapThumbnails() } }), {immediate: false} )
const { data: eventLocationsData, pending: eventLocationsPending, execute: eventLocationsExecute } = await useLazyAsyncData(() => $fetch('/api/strapi-rest', { query: { path: queryEventLocationMapThumbnails({filters: eventsQueryCurrentAndUpcomingFilterParams()}) } }), {immediate: false} )
const { data: focusWeekData, pending: focusWeekPending, execute: focusWeekExecute } = await useLazyAsyncData(() => $fetch('/api/strapi-rest', { query: { path: queryFocusWeekMapThumbnails() } }), {immediate: false} )
const { data: venuesData, pending: venuesPending, execute: venuesExecute } = await useLazyAsyncData(() => $fetch('/api/strapi-rest', { query: { path: queryVenueEntryMapThumbnails() } }), {immediate: false} )


watchOnce(rootElementIsVisible, async (value) => {
  if(value) {
    await clustersExecute()
    await citiesExecute()
    await eventLocationsExecute()
    await focusWeekExecute()
    await venuesExecute()
  }
})

const pending = computed(() => clustersPending.value || citiesPending.value || eventLocationsPending.value || focusWeekPending.value || venuesPending.value)

// cities
const citiesDataWithGeoJson = computed(() => citiesData?.value?.data?.filter(i => i?.attributes?.geojson)?.map( (i) => { return { mapType: 'city', ...i } }) || [])

// map entries
const eventLocationFiltered = computed(() => mapFilteredEventLocationsEntries(eventLocationsData?.value?.data, filterCategoryIds.value, filterCityIds.value, filterVenueIds.value, filterFocusWeekIds.value))
const focusWeekDataFiltered = computed(() => mapFilteredFocusWeekDataEntries(focusWeekData?.value?.data, filterCategoryIds.value, filterCityIds.value, filterVenueIds.value, filterFocusWeekIds.value))
const venuesDataFiltered = computed(() => mapFilteredMapVenueEntries(venuesData?.value?.data, filterCategoryIds.value, filterCityIds.value, filterVenueIds.value, filterFocusWeekIds.value))
const entries = computed(() => {
  return [ ...venuesDataFiltered.value, ...eventLocationFiltered.value, ...focusWeekDataFiltered.value ]?.reverse() || []
})

// map
const fontString = "'NeutralStd', 'helvetica neue', 'helvetica', 'arial', 'sans-serif'"

const mapRef = useMapboxRef('mapElement');
const mapLoaded = ref(false);
const zoomDefault = 10.5
const zoomMin = 10
const zoomMax = 18
const zoom = ref(zoomDefault)

const setZoomValue = useThrottleFn((event) => {
  const zoomValue = event?.target?.getZoom() || event?.getZoom()
  zoom.value = zoomValue
}, 5)


const zoomTo = (val) => {
  if (!mapRef.value) return
  mapRef.value?.zoomTo(val, { essential: false })
}

const flyTo = (lng, lat, zoom) => {
  if (!lng || !lat) return
  mapRef.value?.jumpTo({
    center: [lng, lat],
    zoom: zoom || 12.5,
    essential: false
  })
}

const onLoad = () => {
  mapLoaded.value = true

  if (route.query.lng && route.query.lat) {
    flyTo(route.query.lng, route.query.lat, route.query.zoom || 12)
  }
}

const removeLayer = (id) => {
  mapRef.value?.removeLayer(id)
}

const enableDragPan  = (bool) => {
  if ( bool === true ) {
    mapRef.value.dragPan?.enable()
  } else {
    mapRef.value.dragPan?.disable()
  }
}
</script>

<style module>
.root[data-view-option='clusters'] {
  --map--height: calc(var(--100svh) - var(--unit--vertical) * 2);
  clip-path: inset(0% 0% 0% 0% round var(--border-radius--default));
}

.root[data-view-option='modal'] {
  --map--height: var(--100dvh);
}

.root {
  composes: font-default from global;

  position: relative;
  overflow: hidden;

  height: var(--map--height, 100);
  width: 100%;

  background-color: #cbe5f4;

  display: flex;
  flex-direction: column;

  --z-index--marker: calc(var(--max-z-index) + 0);
  --z-index--zoom: calc(var(--max-z-index) + 1);
  --z-index--buttonToClose: calc(var(--max-z-index) + 2);
  --z-index--nav: calc(var(--max-z-index) + 3);
  --z-index--popup: calc(var(--max-z-index) + 4);

  --nav--width: 400px;
  --nav--max-width: calc(100% - var(--unit--horizontal) * 2 - var(--width--button--round));

  --map--marker-with-popup--box-shadow: 0 0 0.5rem rgba(0,0,0,.15);
  --map--pill--box-shadow: 0 0 calc(var(--unit--spacer) / 2) rgba(0, 0, 0, 0.25);
}

.root :global(.mapboxgl-ctrl) {
  display: none !important;
}

.root :global(.mapboxgl-popup-tip) {
  visibility: hidden;
}

.root :global(.mapboxgl-marker):has(*[data-type='cluster']) {
  z-index: 5;
}

.root :global(.mapboxgl-marker):has(*[data-type='city']) {
  z-index: 4;
}

.root :global(.mapboxgl-marker):has(*[data-type='venue']) {
  z-index: 3;
}

.root :global(.mapboxgl-marker):has(*[data-type='focus-week']) {
  z-index: 3;
}

.root :global(.mapboxgl-marker):has(*[data-type='event-location']) {
  z-index: 2;
}

.root :global(.mapboxgl-marker):has(*[data-type='route']) {
  z-index: 1;
}

.root :global(.mapboxgl-marker):hover {
  z-index: var(--z-index--marker);
}

.nav {
  composes: reset-scrollbar from global;
  position: absolute;
  z-index: var(--z-index--nav);
  top: calc(var(--unit--vertical) - var(--padding));
  left: calc(var(--unit--horizontal) - var(--padding));

  --padding: calc(var(--unit--spacer) * 2);
  padding: var(--padding);

  max-height: 100%;
  width: var(--nav--width);
  max-width: var(--nav--max-width);

  display: flex;
  flex-direction: column;
  gap: var(--unit--vertical);

  overflow: scroll;
}
</style>
